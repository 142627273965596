import papers from '../images/papers.png';
const metadata = {
  title: 'Material',
  routes: {
    '/oversikt': {
      label: 'Översikt',
      header: {
        backgroundImage: papers
      }
    },
    '/bestall-material': {
      label: 'Beställ material',
      header: {
        backgroundImage: papers
      }
    },
    '/mina-bestallningar': {
      label: 'Mina beställningar',
      header: {
        backgroundImage: papers
      }
    },
    '/kampanjutskick': {
      label: 'Kampanjutskick',
      header: {
        backgroundImage: papers
      }
    },
    '/tilldelning': {
      label: 'Tilldelning',
      header: {
        backgroundImage: papers
      }
    },
    '/skyltmaterial': {
      label: 'Skyltmaterial',
      header: {
        backgroundImage: papers
      }
    }
  }
};
export { metadata };