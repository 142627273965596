import { getClassNamesFromArray } from '@common/utils';
import './styles.less';
const {
  ReactBrand: Brand,
  ReactIcon: Icon
} = svs.ui;
const {
  urlMapping
} = svs.core;
const oneTrustEnabled = svs?.components?.onetrust?.data?.enabled;
export const Footer = ({
  isCenter
}) => <div className={`footer bg-grey-700 padding-v-4 f-content ${!isCenter ? 'site-menu-border' : ''}`} id="footer">
    <div className={getClassNamesFromArray(['ompo-main-content', isCenter && 'margin-center'])}>
      <div className="grid-row">
        <div className="col-xs-12 col-sm-6 col-am-3">
          <h4>Adress Visby</h4>
          <p>
            Norra Hansegatan 17
            <br />
            621 80 Visby
          </p>
          <h4>Adress Arenastaden</h4>
          <p>
            Evenemangsgatan 17
            <br />
            169 79 Solna
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 col-am-3">
          <h4>Integritet</h4>
          <p>
            <a href={urlMapping.get('termsIntegrityPersonData')} rel="noreferrer" target="_blank">
              Personuppgifter

              <Icon icon="external_link" size="100" />
            </a>
            <br />
            <a href={urlMapping.get('termsIntegrityCookies')}>
              Användning av cookies
            </a>
            <br />
            {oneTrustEnabled && !window.location.href.includes('/villkor-och-integritet') && <a onClick={() => {
            window.OneTrust.ToggleInfoDisplay();
          }}>
                Välj dina cookies
              </a>}
          </p>

          <h4>Avtal</h4>
          <p>
            <a href="/avtal-och-riktlinjer">
              Avtal och riktlinjer
            </a>
          </p>

          <h4>Ombudssupport</h4>
          <p>
            <a href="/ombudssupport">
              Frågor och svar
            </a>
            <br />
            Telefon:
            {' '}
            <a href="tel:020-78 83 55">020-78 83 55</a>
            <br />
            <a href="/ombudssupport">
              Kontakta oss
            </a>
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 col-am-3">
          <h4>Mer information</h4>
          <p>
            <a href="https://om.svenskaspel.se/" rel="noreferrer" target="_blank">
              Om Svenska Spel&nbsp;
              <Icon icon="external_link" size="100" />
            </a>
            <br />
            <a href="https://www.svenskaspelportal.se/spelkoll" rel="noreferrer">
              Spelkoll
            </a>
            <br />
            <a href="/whistleblower">
              Visselblåsning
            </a>
            <br />
            <a href="https://cdn1.svenskaspel.net/content/cms/documents/599b13c1-0e65-429a-804e-eb23e983778a/1.2/fullmakt_svs_2021_16.pdf" rel="noreferrer" target="_blank">
              Fullmakt
              <Icon icon="external_link" size="100" />
            </a>
          </p>
        </div>
        <div className="col-xs-12 col-sm-6 col-am-3 company u-text-center-am">
          <div className="margin-xs-bottom-1">
            <Brand alt="Svenska Spel" brand="svenskaspel-twolines-neg" emblem />
          </div>

          <p>
            AB Svenska Spel
            <br />
            Org.nummer: 556460-1812
            <br />
            <a href="tel:0770111111">
              Kundservice: 0770-11 11 11
            </a>
          </p>

          <p>
            Styrelsen säte: Visby
          </p>
        </div>
      </div>
    </div>
  </div>;